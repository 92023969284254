import React from 'react'
import { type HeadFC, type PageProps } from 'gatsby'
import { HeaderAndFooterLayout } from '../../layouts/HeaderAndFooter'
import { css } from '../../../styled-system/css'
import { Albums } from '../../components/photographie/Albums'

const IndexPage: React.FC<PageProps> = () => {
  return (
    <HeaderAndFooterLayout>
      <div>
        <div
          className={css({
            marginLeft: '5rem',
            marginTop: '1rem',
            marginBottom: '1.5rem',
            lgDown: {
              marginLeft: '1rem',
            },
          })}
        >
          <h2
            className={css({
              color: '#594A2E',
              fontSize: '2.25rem',
              fontFamily: 'Assistant',
              fontWeight: '300',
              lgDown: {
                fontSize: '1.75rem',
              },
            })}
          >
            Réservation de ma séance photo
          </h2>

          <p
            className={css({
              color: '#6D758F',
              fontSize: '1.25rem',
              marginTop: '1rem',
              lgDown: {
                fontSize: '.9rem',
                marginTop: '.25rem',
              },
            })}
          >
            Veuillez sélectionner le forfait que vous souhaitez réserver
          </p>
        </div>

        <Albums />
      </div>
    </HeaderAndFooterLayout>
  )
}

export default IndexPage

export const Head: HeadFC = () => <title>Choix Réservation | Digit Universe</title>